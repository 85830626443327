<template>
  <ServicesDescription :isFooter="false" :isPageWrapper="true">
    <template #title>
      Раздел находится в разработке
    </template>
    <template #body>
      <p>
        Уважаемые абоненты, данный раздел находится в разработке.
      </p>
      <p>
        В ближайшее время мы наполним его информацией, и Вы сможете использовать все преимущества
        Личного кабинета.
      </p>
      <p>
        Подключить услугу или получить всю интересующую информацию Вы можете по телефону 8 (495)
        44-96-000.
      </p>
    </template>
  </ServicesDescription>
</template>

<script>
import ServicesDescription from '@/components/shared/ServicesDescription.vue';

export default {
  components: {
    ServicesDescription
  }
};
</script>

<style></style>
